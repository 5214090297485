<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CASH FLOW</h4></v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-layout col>
          <v-flex xs12 md5>
            <v-form ref="form" class="multi-col-validation">
              <v-layout row wrap class="mx-1 mb-5">
                <v-flex xs12 md12>
                  <v-text-field v-model="month_of" class="mx-2" dense label="Month Of" readonly
                                disabled></v-text-field>
                </v-flex>
                <v-flex xs12 md12>
                  <v-select
                    v-model="category_id"
                    class="mx-2"
                    dense
                    outlined
                    label="Category"
                    :items="category_items"
                    item-value="id"
                    item-text="category"
                    @change="selected_category"
                    :rules="rules.combobox_rule"
                  ></v-select>
                  <v-checkbox
                    v-if="category_id != '' && !is_final_report"
                    v-model="can_final_report"
                    label="Is Final Report?"
                  ></v-checkbox>
                  <v-col cols="12" v-show="alert2">
                    <v-alert color="warning" text class="mb-0">
                      <div class="d-flex align-start">
                        <v-icon color="warning">
                          {{ icons.mdiAlertOutline }}
                        </v-icon>
                        <div class="ms-3">
                          <p class="text-base font-weight-medium mb-1">
                            {{ alert_message2 }}
                          </p>
                        </div>
                      </div>
                    </v-alert>
                  </v-col>
                  <v-col cols="12" md="12" v-if="can_final_report">
                    <v-btn class="w-full" color="primary" @click="final_report"
                           v-if="!saving_data2">
                      Final Report
                    </v-btn>
                    <v-progress-circular
                      :size="50"
                      :width="5"
                      color="primary"
                      indeterminate
                      v-else
                    ></v-progress-circular>
                  </v-col>
                </v-flex>
                <v-flex xs12 md12>
                  <v-select
                    v-model="bank_id"
                    class="mx-2"
                    dense
                    outlined
                    label="Bank"
                    :items="bank_items"
                    item-value="id"
                    item-text="bank_code"
                    @change="selected_bank"
                    :rules="rules.combobox_rule"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    v-model="account_name"
                    class="mx-2"
                    dense
                    outlined
                    label="Account Name"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    v-model="account_no"
                    class="mx-2"
                    dense
                    outlined
                    label="Account #"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    v-model="last_month_balance"
                    class="mx-2"
                    dense
                    outlined
                    label="Last Month Balance"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    v-model="total_deposit"
                    class="mx-2"
                    dense
                    outlined
                    label="Total Deposit"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    v-model="total_withdrew"
                    class="mx-2"
                    dense
                    outlined
                    label="Total Withdrew"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    v-model="remaining_balance"
                    class="mx-2"
                    dense
                    outlined
                    label="Remaining Balance"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md12 v-if="bank_id != '' && !is_final_report">
                  <v-toolbar dense dark color="primary mt-3">
                    <v-toolbar-title><h4 class="font-weight-light">TRANSACTION DATA</h4>
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-layout row wrap class="mx-1 mt-3" dense>
                    <v-flex xs12 md12>
                      <v-select
                        v-model="transaction"
                        class="mx-2"
                        dense
                        outlined
                        label="Transaction"
                        :items="['Deposit', 'Withdrawal']"
                        @change="selected_transaction"
                        :rules="rules.combobox_rule"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-checkbox
                        v-if="transaction === 'Withdrawal'"
                        v-model="is_auto_debit"
                        label="Auto Debit Transaction?"
                        dense
                        outlined
                        @change="selected_transaction"
                      ></v-checkbox>
                      <v-checkbox
                        v-else
                        v-model="is_auto_debit"
                        label="Return Fund/Fund Deposit Transaction?"
                        dense
                        outlined
                        @change="selected_transaction"
                      ></v-checkbox>
                    </v-flex>
                    <v-flex xs12 md4 v-if="transaction === 'Withdrawal' && is_auto_debit">
                      <v-select
                        v-model="auto_debit_cv"
                        class="mx-2"
                        dense
                        outlined
                        label="Auto Debit CV#"
                        :items="auto_debit_cv_items"
                        item-value="id"
                        item-text="code"
                        @change="selected_auto_debit_cv"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 md4 v-if="transaction === 'Withdrawal' && is_auto_debit">
                      <v-select
                        v-model="auto_debit_ckv"
                        class="mx-2"
                        dense
                        outlined
                        label="Auto Debit CKV#"
                        :items="auto_debit_ckv_items"
                        item-value="id"
                        item-text="code"
                        @change="selected_auto_debit_ckv"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 md4 v-if="transaction === 'Withdrawal' && is_auto_debit">
                      <v-select
                        v-model="auto_debit_ftv"
                        class="mx-2"
                        dense
                        outlined
                        label="Auto Debit FTV#"
                        :items="auto_debit_ftv_items"
                        item-value="id"
                        item-text="code"
                        @change="selected_auto_debit_ftv"
                      ></v-select>
                    </v-flex>

                    <v-flex xs12 md4 v-if="transaction === 'Deposit' && is_auto_debit">
                      <v-select
                        v-model="dep_receivables"
                        class="mx-2"
                        dense
                        outlined
                        label="Deposits"
                        :items="dep_receivables_items"
                        item-value="id"
                        item-text="code"
                        @change="selected_dep_receivables"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 md4 v-if="transaction === 'Deposit' && is_auto_debit">
                      <v-select
                        v-model="return_fund"
                        class="mx-2"
                        dense
                        outlined
                        label="Return Fund"
                        :items="return_fund_items"
                        item-value="id"
                        item-text="code"
                        @change="selected_return_fund"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 md4 v-if="transaction === 'Deposit' && is_auto_debit">
                      <v-select
                        v-model="ftv_deposit"
                        class="mx-2"
                        dense
                        outlined
                        label="FTV Deposit"
                        :items="ftv_deposit_items"
                        item-value="id"
                        item-text="code"
                        @change="selected_ftv_deposit"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 md12 v-if="transaction === 'Withdrawal' && !is_auto_debit">
                      <v-autocomplete
                        class="mx-2"
                        outlined
                        v-model="cheque_no_data"
                        :items="cheque_no_items"
                        :loading="isLoading"
                        @keyup.enter="searching_cheque_no($event.target.value)"
                        hide-selected
                        hide-no-data
                        item-text="cheque_no"
                        item-value="id"
                        label="Cheque #"
                        placeholder="Press Enter to Search "
                        :prepend-icon="icons.mdiAccountSearchOutline"
                        @change="get_cheque_no_items_info"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md12 v-if="can_view_voucher">
                      <v-btn class="w-full mb-3" color="info" @click="view_voucher"
                             v-if="!printing_voucher">
                        View Voucher
                      </v-btn>
                      <v-progress-circular
                        :size="50"
                        :width="5"
                        color="primary"
                        indeterminate
                        v-else
                      ></v-progress-circular>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-text-field
                        v-model="date"
                        class="mx-2"
                        dense
                        outlined
                        label="Date"
                        type="date"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-select
                        v-model="particulars"
                        class="mx-2"
                        dense
                        outlined
                        label="Particulars"
                        :items="particulars_items"
                        :rules="rules.combobox_rule"
                        @change="selected_particulars"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 md12 v-if="!this.specify_disable">
                      <v-text-field
                        v-model="specify"
                        class="mx-2"
                        dense
                        outlined
                        label="Specify"
                        :rules="rules.default_max_600_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-text-field
                        v-model="amount"
                        class="mx-2"
                        dense
                        outlined
                        label="Amount"
                        type="number"
                        min="0"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        v-if="is_can_encode"
                        @keyup.enter="save_cash_flow"
                      ></v-text-field>
                      <v-text-field
                        v-model="amount"
                        class="mx-2"
                        dense
                        outlined
                        label="Amount"
                        readonly
                        v-else
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-text-field v-model="payee" class="mx-2" dense label="Payee"
                                    disabled></v-text-field>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-text-field
                        v-model="allocation_of_fund"
                        class="mx-2"
                        dense
                        label="Allocation of Fund"
                        disabled
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-col cols="12" v-show="alert">
                    <v-alert color="warning" text class="mb-0">
                      <div class="d-flex align-start">
                        <v-icon color="warning">
                          {{ icons.mdiAlertOutline }}
                        </v-icon>

                        <div class="ms-3">
                          <p class="text-base font-weight-medium mb-1">
                            {{ alert_message }}
                          </p>
                        </div>
                      </div>
                    </v-alert>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-btn class="w-full" color="primary" @click="save_cash_flow"
                           v-if="!saving_data">
                      Save changes
                    </v-btn>
                    <v-progress-circular
                      :size="50"
                      :width="5"
                      color="primary"
                      indeterminate
                      v-else
                    ></v-progress-circular>
                  </v-col>
                </v-flex>
              </v-layout>
            </v-form>
          </v-flex>
          <v-flex xs12 md7>
            <v-data-table
              dense
              :headers="headers"
              height="600"
              :items="data_items"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.deposit === '' ? '' : formatPrice(item.deposit) }}
                  </td>
                  <td>
                    {{ item.withdrew === '' ? '' : formatPrice(item.withdrew) }}
                  </td>
                  <td>
                    {{ item.cheque_no === 0 ? '' : item.cheque_no }}
                  </td>
                  <td>
                    <v-chip>{{ item.date }}</v-chip>
                  </td>
                  <td>
                    {{ formatPrice(item.balance) }}
                  </td>
                  <td>
                    {{ item.particulars }}
                  </td>
                  <td v-if="!is_final_report">
                    <v-icon class="mr-2" color="info" @click="edit_details(item)"
                            v-if="!saving_data">
                      {{ icons.mdiPencil }}
                    </v-icon>
                    <v-icon class="mr-2" color="error" @click="delete_bank_transaction(item)"
                            v-if="!saving_data">
                      {{ icons.mdiDelete }}
                    </v-icon>
                    <v-progress-circular
                      :size="50"
                      :width="5"
                      color="primary"
                      indeterminate
                      v-if="saving_data"
                    ></v-progress-circular>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <v-dialog v-model="can_edit" persistent max-width="30%">
      <EditData :key="key" :data="transaction_data" :category_id="this.category_id"
                v-on:response="on_change"></EditData>
      <v-btn color="error" @click="can_edit = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiAccountCashOutline,
  mdiInformationOutline,
  mdiDelete,
  mdiPencil
} from '@mdi/js'
import moment from 'moment'
import {mapActions, mapGetters} from 'vuex'
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
import EditData from './EditData'

const initialState = () => {
  return {
    saving_data2: false,
    saving_data: false,
    alert2: false,
    alert: false,
    alert_message2: '',
    alert_message: '',
    can_view_voucher: false,
    key: 0,

    headers: [
      {text: 'Dep. Amount', value: 'tin', sortable: false},
      {text: 'With. Amount', value: 'last_name', sortable: false},
      {text: 'Cheque #', value: 'last_name', sortable: false},
      {text: 'Date', value: 'last_name', sortable: false},
      {text: 'Balance', value: 'last_name', sortable: false},
      {text: 'Particulars', value: 'last_name', sortable: false},
      {text: 'Action', value: 'last_name', sortable: false},
    ],
    category_id: '',
    sorted_by: '',
    month_of: '',
    category_items: [],
    data_items: [],
    month_of_id: 0,
    bank_id: 0,
    bank_balance: 0,
    bank_items: [],
    account_no: '',
    account_name: '',
    last_month_balance: '0.00',
    total_deposit: '0.00',
    total_withdrew: '0.00',
    remaining_balance: '0.00',

    amount: '',
    specify: '',
    particulars: '',
    date: '',
    cheque_no: '',
    cheque_no_data: '',
    ftv_deposit: '',
    return_fund: '',
    dep_receivables: '',
    ggc_dep_id: '',
    auto_debit_ftv: '',
    auto_debit_ckv: '',
    auto_debit_cv: '',
    transaction: '',
    category_selected: '',

    is_can_encode: true,

    ftv_deposit_items: [],
    return_fund_items: [],
    dep_receivables_items: [],
    particulars_items: [],

    auto_debit_ftv_items: [],
    auto_debit_ckv_items: [],
    auto_debit_cv_items: [],

    is_auto_debit: false,

    is_dep_receivables: false,
    is_ggc_dep: false,
    is_return_fund: false,
    is_ckv_return_fund: false,
    is_ftv_deposit: false,
    is_auto_debit_ftv: false,
    is_auto_debit_ckv: false,
    is_auto_debit_cv: false,
    is_cheque: false,
    specify_disable: false,
    is_final_report: false,
    can_final_report: false,

    cheque_no_items: [],
    isLoading: false,

    request_data_id: '',
    cheque_date: '',
    payee: '',
    allocation_of_fund: '',

    cv_id: '',
    ckv_id: '',
    ftv_id: '',

    printing_voucher: false,
    can_edit: false,
    transaction_data: {},
  }
}
export default {
  components: {
    snackBarDialog,
    EditData,
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiAccountCashOutline,
        mdiInformationOutline,
        mdiDelete,
        mdiPencil,
      },
    }
  },
  data() {
    return initialState()
  },
  mounted() {
    this.initialize_data()
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('authentication', ['employee_id', 'address', 'contact', 'name', 'department', 'position']),
    ...mapGetters('system_data', [
      'snackbar',
      'snackbar_color',
      'snackbar_text',
      'company_logo',
      'damayan',
      'chapel',
      'factory',
      'coop',
      'printing',
      'accounting',
      'admin',
      'amd',
      'audit',
      'book',
      'creative',
      'ecut',
      'hr',
      'it',
      'prot',
      'trea',
    ]),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('bank_transaction', [
      'initialization_cash_flow',
      'get_transaction_of_bank',
      'cash_flow_initialize_data',
      'register_cash_flow',
      'delete_cash_flow',
      'view_voucher_from_cash_flow',
    ]),
    ...mapActions('cheque_book_series', ['search_cheque_no_used']),
    ...mapActions('category', ['category_to_final_report']),
    initialize_data() {
      this.initialization_cash_flow({
        employee_id: this.employee_id,
      })
        .then(response => {
          this.can_final_report = false
          this.category_items = response.data
          this.transaction = ''
          this.category_id = ''
          this.bank_id = ''
          this.bank_items = []
          this.data_items = []
          this.account_name = ''
          this.account_no = ''
          this.last_month_balance = ''
          this.total_deposit = ''
          this.total_withdrew = ''
          this.remaining_balance = ''
        })
        .catch(error => {
          console.log(error)
        })
    },
    date_format(value) {
      return moment(value)
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    selected_month() {
      const data = new FormData()
      data.append('month_of_id', this.month_of)
    },
    selected_category(value) {
      var index = this.category_items
        .map(function (x) {
          return x.id
        })
        .indexOf(value)
      if (index != -1) {
        this.category_selected = this.category_items[index].category
        this.bank_items = this.category_items[index].bank_depository
        this.month_of = this.category_items[index].transaction_month.month_of
        this.month_of_id = this.category_items[index].month_of_id
        this.bank_id = ''
        this.data_items = []
        this.account_name = ''
        this.account_no = ''
        this.last_month_balance = ''
        this.total_deposit = ''
        this.total_withdrew = ''
        this.remaining_balance = ''
        this.transaction = ''
        this.is_final_report = this.category_items[index].is_can_new_month
      }
    },
    selected_bank() {
      var index = this.bank_items
        .map(function (x) {
          return x.id
        })
        .indexOf(this.bank_id)
      if (index != -1) {
        if (!this.saving_data) {
          this.transaction = ''
        }
        this.account_name = this.bank_items[index].bank_name
        this.account_no = this.bank_items[index].account_no
        const data = new FormData()
        data.append('month_of_id', this.month_of_id)
        data.append('bank_id', this.bank_id)
        data.append('is_cash_flow', 1)
        this.get_transaction_of_bank(data)
          .then(response => {
            this.data_items = response.data[0].bank_transaction_history
            this.last_month_balance = response.data[0].last_month_balance
            this.total_deposit = response.data[0].total_deposit
            this.total_withdrew = response.data[0].total_withdrew
            this.remaining_balance = response.data[0].remaining_balance
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    selected_transaction() {
      const data = new FormData()
      data.append('month_of_id', this.month_of_id)
      data.append('bank_id', this.bank_id)
      data.append('transaction', this.transaction)
      this.is_can_encode = true
      if (this.transaction === 'Withdrawal') {
        this.is_can_encode = false
      }
      this.can_view_voucher = false
      this.is_dep_receivables = false
      this.is_ggc_dep = false
      this.is_return_fund = false
      this.is_ckv_return_fund = false
      this.is_ftv_deposit = false
      this.is_auto_debit_ftv = false
      this.is_auto_debit_ckv = false
      this.is_auto_debit_cv = false
      this.is_cheque = false
      // this.date = ''
      // this.particulars = ''
      this.particulars_items = []
      this.specify = ''
      this.amount = 0
      this.dep_receivables = ''
      this.ggc_dep_id = ''
      this.return_fund = ''
      this.return_fund_cheque = ''
      this.ftv_deposit = ''
      this.auto_debit_ftv = ''
      this.auto_debit_ckv = ''
      this.auto_debit_cv = ''
      this.cheque_no_data = ''
      this.cheque_no = ''
      this.cheque_no_items = []
      this.request_data_id = ''
      this.payee = ''
      this.allocation_of_fund = ''
      this.cheque_date = ''
      this.cv_id = ''
      this.ckv_id = ''
      this.ftv_id = ''
      this.specify = ''
      switch (this.category_selected) {
        case 'Damayan':
          this.particulars_items = ['DAM', 'INT', 'INT. LESS WTX', 'OTHERS - ', 'MAINTAINING BALANCE - '
            , 'LOAN PAYMENT - ',
            'LEGAL & DOC STAMP FEE - ',
            'LOAN INSURANCE FEE - ',
            'PROCESSING FEE - ',
            'RET. FUND - ',
            'FTV - ',]
          break
        case 'Chapel':
          this.particulars_items = ['SERV', 'INT', 'INT. LESS WTX', 'OTHERS - ', 'MAINTAINING BALANCE - ']
          break
        case 'Coop':
          this.particulars_items = [
            'MAINTAINING BALANCE - ',
            'OTHERS - ',
            'REGULAR SAVINGS - ',
            'TIME DEPOSIT - ',
            'PAID UP CAPITAL - ',
            'LOAN PAYMENT - ',
            'RAW LOTS PAYMENT - ',
            'MEMBERSHIP FEE - ',
            'PROCESSING FEE',
            'FOUNDATION SHARE - ',
            'LOAN INSURANCE - ',
            'OPENING SAVINGS ACCOUNT - ',
            'COOP PROTEK - ',
            'SHARE CAPITAL - ',
            'MORTUARY INSURANCE - ',
            'INT',
            'INT. LESS WTX',
          ]
          break
        case 'Chapel':
          this.particulars_items = ['SALES', 'INT', 'INT. LESS WTX', 'OTHERS - ', 'MAINTAINING BALANCE - ']
          break
        case 'HO':
          this.particulars_items = [
            'LOAN PAYMENT - ',
            'LEGAL & DOC STAMP FEE - ',
            'LOAN INSURANCE FEE - ',
            'PROCESSING FEE - ',
            'RET. FUND - ',
            'FTV - ',
            'OTHERS - ',
            'INT',
            'INT. LESS WTX',
            'MAINTAINING BALANCE - ',
          ]
          break
        default:
          this.particulars_items = ['INCOME - ', 'PURCHASE - ', 'INT', 'INT. LESS WTX', 'OTHERS - ', 'MAINTAINING BALANCE - ', 'INVESTMENT GAIN (LOSS) - ']
          break
      }
      this.cash_flow_initialize_data(data)
        .then(response => {
          this.dep_receivables_items = response.data[0].dep_receivables_items
          this.return_fund_items = response.data[0].return_fund_items
          this.ftv_deposit_items = response.data[0].ftv_deposit_items
          this.auto_debit_ftv_items = response.data[0].auto_debit_ftv_items
          this.auto_debit_ckv_items = response.data[0].auto_debit_ckv_items
          this.auto_debit_cv_items = response.data[0].auto_debit_cv_items
        })
        .catch(error => {
          console.log(error)
        })
    },
    selected_particulars(value) {
      this.specify_disable = false
      if (
        value === 'INT' ||
        value === 'INT. LESS WTX' ||
        value === 'SERV' ||
        value === 'KGPY' ||
        value === 'DAM' ||
        value === 'PURCHASE'
      ) {
        this.specify_disable = true
      }
    },
    selected_dep_receivables(value) {
      var index = this.dep_receivables_items
        .map(function (x) {
          return x.id
        })
        .indexOf(value)
      if (index != -1) {
        this.is_can_encode = false
        this.is_return_fund = false
        this.is_ckv_return_fund = false
        this.is_ftv_deposit = false
        this.is_auto_debit_ftv = false
        this.is_auto_debit_ckv = false
        this.is_auto_debit_cv = false
        this.is_cheque = false
        this.request_data_id = ''
        this.cheque_no = ''
        this.payee = ''
        this.allocation_of_fund = ''
        this.cv_id = ''
        this.ckv_id = ''
        this.ftv_id = ''
        this.return_fund = ''
        this.return_fund_cheque = ''
        this.ftv_deposit = ''
        this.auto_debit_ftv = ''
        this.auto_debit_ckv = ''
        this.auto_debit_cv = ''
        this.amount = this.dep_receivables_items[index].amount
        this.date = this.dep_receivables_items[index].date
        this.specify = this.dep_receivables_items[index].category
        if (this.category_selected === 'Damayan' && (this.bank_id === 115 || this.bank_id === 116 || this.bank_id === 117)) {
          this.ggc_dep_id = value
          this.dep_receivables = ''
          this.is_ggc_dep = true
          this.particulars_items = ['RET. FUND - ', 'LOAN PAYMENT - ', 'PROCESSING FEE - ', 'LOAN INSURANCE FEE - ', 'NMAPI FEE - ', 'LEGAL & DOC STAMP FEE - ',]
        } else if (this.category_selected === 'Coop' && this.bank_id === 151) {
          this.ggc_dep_id = value
          this.dep_receivables = ''
          this.is_ggc_dep = true
          this.particulars_items = ['RET. FUND - ', 'LOAN PAYMENT - ', 'PROCESSING FEE - ', 'LOAN INSURANCE FEE - ', 'NMAPI FEE - ', 'LEGAL & DOC STAMP FEE - ',]
        } else {
          this.is_dep_receivables = true
          this.particulars = 'RET. FUND - '
          this.particulars_items = [this.particulars]
        }
      }
    },
    selected_return_fund(value) {
      var index = this.return_fund_items
        .map(function (x) {
          return x.id
        })
        .indexOf(value)
      if (index != -1) {
        this.is_can_encode = false
        this.is_dep_receivables = false
        this.is_ggc_dep = false
        this.is_ftv_deposit = false
        this.is_auto_debit_ftv = false
        this.is_auto_debit_ckv = false
        this.is_auto_debit_cv = false
        this.is_cheque = false
        this.cheque_no = ''
        this.payee = ''
        this.dep_receivables = ''
        this.ggc_dep_id = ''
        this.ftv_deposit = ''
        this.auto_debit_ftv = ''
        this.auto_debit_ckv = ''
        this.auto_debit_cv = ''
        this.allocation_of_fund = ''
        this.cv_id = this.return_fund_items[index].cv_id
        this.ckv_id = this.return_fund_items[index].ckv_id
        this.ftv_id = ''
        this.request_data_id = this.return_fund_items[index].request_data_id
        this.amount = this.return_fund_items[index].amount
        this.date = this.return_fund_items[index].date
        this.specify = this.return_fund_items[index].description
        this.particulars = (this.return_fund_items[index].cv_id != '' ? 'RET. FUND CV#' : (this.return_fund_items[index].ckv_id != '' ? 'RET. FUND CKV#' : 'RET. FUND ')) + this.return_fund_items[index].voucher_no
        this.is_return_fund = !this.return_fund_items[index].is_ckv
        this.is_ckv_return_fund = this.return_fund_items[index].is_ckv
        this.return_fund_cheque = this.return_fund_items[index].is_ckv ? this.return_fund_items[index].id : ''
        this.particulars_items = [this.particulars]
      }
    },
    selected_ftv_deposit(value) {
      var index = this.ftv_deposit_items
        .map(function (x) {
          return x.id
        })
        .indexOf(value)
      if (index != -1) {
        this.is_can_encode = false
        this.is_dep_receivables = false
        this.is_ggc_dep = false
        this.is_return_fund = false
        this.is_ckv_return_fund = false
        this.is_ftv_deposit = true
        this.is_auto_debit_ftv = false
        this.is_auto_debit_ckv = false
        this.is_auto_debit_cv = false
        this.is_cheque = false
        this.cheque_no = ''
        this.payee = ''
        this.allocation_of_fund = ''
        this.cv_id = ''
        this.ckv_id = ''
        this.dep_receivables = ''
        this.ggc_dep_id = ''
        this.return_fund = ''
        this.return_fund_cheque = ''
        this.auto_debit_ftv = ''
        this.auto_debit_ckv = ''
        this.auto_debit_cv = ''
        this.ftv_id = this.ftv_deposit_items[index].ftv_id
        this.request_data_id = this.ftv_deposit_items[index].request_data_id
        this.amount = this.ftv_deposit_items[index].amount
        this.date = this.ftv_deposit_items[index].date
        this.specify = this.ftv_deposit_items[index].description
        this.particulars = 'FTV' + this.ftv_deposit_items[index].voucher_no + ' - '
        this.particulars_items = [this.particulars]
      }
    },
    selected_auto_debit_ftv(value) {
      var index = this.auto_debit_ftv_items
        .map(function (x) {
          return x.id
        })
        .indexOf(value)
      if (index != -1) {
        this.can_view_voucher = true
        this.is_can_encode = false
        this.is_dep_receivables = false
        this.is_ggc_dep = false
        this.is_return_fund = false
        this.is_ckv_return_fund = false
        this.is_ftv_deposit = false
        this.is_auto_debit_ftv = true
        this.is_auto_debit_ckv = false
        this.is_auto_debit_cv = false
        this.is_cheque = false
        this.cheque_no = ''
        this.cv_id = ''
        this.ckv_id = ''
        this.dep_receivables = ''
        this.ggc_dep_id = ''
        this.return_fund = ''
        this.return_fund_cheque = ''
        this.ftv_deposit = ''
        this.auto_debit_ckv = ''
        this.auto_debit_cv = ''
        this.ftv_id = this.auto_debit_ftv_items[index].ftv_id
        this.payee = this.auto_debit_ftv_items[index].payee
        this.allocation_of_fund = this.auto_debit_ftv_items[index].allocation_of_fund
        this.request_data_id = this.auto_debit_ftv_items[index].request_data_id
        this.amount = this.auto_debit_ftv_items[index].amount
        this.date = this.auto_debit_ftv_items[index].date
        this.specify = this.auto_debit_ftv_items[index].description
        this.particulars = 'FTV#' + this.auto_debit_ftv_items[index].voucher_no + ' - '
        this.particulars_items = [this.particulars]
      }
    },
    selected_auto_debit_ckv(value) {
      var index = this.auto_debit_ckv_items
        .map(function (x) {
          return x.id
        })
        .indexOf(value)
      if (index != -1) {
        this.can_view_voucher = true
        this.is_can_encode = false
        this.is_dep_receivables = false
        this.is_ggc_dep = false
        this.is_return_fund = false
        this.is_ckv_return_fund = false
        this.is_ftv_deposit = false
        this.is_auto_debit_ftv = false
        this.is_auto_debit_ckv = true
        this.is_auto_debit_cv = false
        this.is_cheque = false
        this.cheque_no = ''
        this.cv_id = ''
        this.dep_receivables = ''
        this.ggc_dep_id = ''
        this.return_fund = ''
        this.return_fund_cheque = ''
        this.ftv_deposit = ''
        this.auto_debit_ftv = ''
        this.auto_debit_cv = ''
        this.ckv_id = this.auto_debit_ckv_items[index].ckv_id
        this.ftv_id = ''
        this.payee = this.auto_debit_ckv_items[index].payee
        this.allocation_of_fund = this.auto_debit_ckv_items[index].allocation_of_fund
        this.request_data_id = this.auto_debit_ckv_items[index].request_data_id
        this.amount = this.auto_debit_ckv_items[index].amount
        this.date = this.auto_debit_ckv_items[index].date
        this.specify = this.auto_debit_ckv_items[index].description
        this.particulars = 'CKV#' + this.auto_debit_ckv_items[index].voucher_no + ' - '
        this.particulars_items = [this.particulars]
      }
    },
    selected_auto_debit_cv(value) {
      var index = this.auto_debit_cv_items
        .map(function (x) {
          return x.id
        })
        .indexOf(value)
      if (index != -1) {
        this.can_view_voucher = true
        this.is_can_encode = false
        this.is_dep_receivables = false
        this.is_ggc_dep = false
        this.is_return_fund = false
        this.is_ckv_return_fund = false
        this.is_ftv_deposit = false
        this.is_auto_debit_ftv = false
        this.is_auto_debit_ckv = false
        this.is_auto_debit_cv = true
        this.is_cheque = false
        this.request_data_id = ''
        this.cheque_no = ''
        this.payee = ''
        this.allocation_of_fund = ''
        this.dep_receivables = ''
        this.ggc_dep_id = ''
        this.return_fund = ''
        this.return_fund_cheque = ''
        this.ftv_deposit = ''
        this.auto_debit_ftv = ''
        this.auto_debit_ckv = ''
        this.cv_id = this.auto_debit_cv_items[index].cv_id
        this.ckv_id = ''
        this.ftv_id = ''
        this.amount = this.auto_debit_cv_items[index].amount
        this.date = this.auto_debit_cv_items[index].date
        this.specify = this.auto_debit_cv_items[index].description
        this.particulars = 'CV#' + this.auto_debit_cv_items[index].voucher_no + ' - '
        this.particulars_items = [this.particulars]
      }
    },
    searching_cheque_no(value) {
      this.isLoading = true
      this.search_cheque_no_used({
        bank_id: this.bank_id,
        cheque_no: value,
      }).then(response => {
        this.cheque_no_items = response.data
        this.isLoading = false
      })
    },
    get_cheque_no_items_info(value) {
      if (this.cheque_no_items.length > 0) {
        var index = this.cheque_no_items
          .map(function (x) {
            return x.id
          })
          .indexOf(value)
        if (index != -1) {
          this.can_view_voucher = true
          this.is_can_encode = false
          this.is_dep_receivables = false
          this.is_ggc_dep = false
          this.is_return_fund = false
          this.is_ckv_return_fund = false
          this.is_ftv_deposit = false
          this.is_auto_debit_ftv = false
          this.is_auto_debit_ckv = false
          this.is_auto_debit_cv = false
          this.is_cheque = true

          this.dep_receivables = ''
          this.ggc_dep_id = ''
          this.return_fund = ''
          this.return_fund_cheque = ''
          this.ftv_deposit = ''
          this.auto_debit_ftv = ''
          this.auto_debit_ckv = ''
          this.auto_debit_cv = ''

          this.cv_id = this.cheque_no_items[index].cv_id
          this.ckv_id = this.cheque_no_items[index].ckv_id
          this.ftv_id = this.cheque_no_items[index].ftv_id
          this.payee = this.cheque_no_items[index].payee
          this.allocation_of_fund = this.cheque_no_items[index].allocation_of_fund
          this.cheque_date = this.cheque_no_items[index].date
          this.request_data_id = this.cheque_no_items[index].request_data_id
          this.cheque_no = this.cheque_no_items[index].cheque_no
          this.amount = this.cheque_no_items[index].amount
          // this.date = this.cheque_no_items[index].date
          this.specify = this.cheque_no_items[index].description
          this.particulars = this.cheque_no_items[index].voucher_no
          this.particulars_items = [this.particulars]
        } else {
          this.dep_receivables = ''
          this.ggc_dep_id = ''
          this.return_fund = ''
          this.return_fund_cheque = ''
          this.ftv_deposit = ''
          this.auto_debit_ftv = ''
          this.auto_debit_ckv = ''
          this.auto_debit_cv = ''
          this.cv_id = ''
          this.ckv_id = ''
          this.ftv_id = ''
          this.payee = ''
          this.allocation_of_fund = ''
          this.cheque_date = ''
          this.cheque_no = ''
          this.amount = ''
          this.date = ''
          this.specify = ''
          this.particulars = ''
          this.particulars_items = [this.particulars]
        }
      } else {
        this.dep_receivables = ''
        this.ggc_dep_id = ''
        this.return_fund = ''
        this.return_fund_cheque = ''
        this.ftv_deposit = ''
        this.auto_debit_ftv = ''
        this.auto_debit_ckv = ''
        this.auto_debit_cv = ''
        this.cv_id = ''
        this.ckv_id = ''
        this.ftv_id = ''
        this.payee = ''
        this.allocation_of_fund = ''
        this.cheque_date = ''
        this.cheque_no = ''
        this.amount = ''
        this.date = ''
        this.specify = ''
        this.particulars = ''
        this.particulars_items = [this.particulars]
      }
    },
    async save_cash_flow() {
      this.saving_data = true
      this.alert = false
      if (this.$refs.form.validate()) {
        const data = new FormData()
        data.append('category_id', this.category_id)
        data.append('month_of_id', this.month_of_id)
        data.append('bank_id', this.bank_id)
        data.append('transaction', this.transaction)
        data.append('date', this.date)
        data.append('amount', this.amount)
        data.append('cheque_no', this.cheque_no)
        data.append('cheque_date', this.cheque_date)
        data.append('payee', this.payee)
        data.append('allocation_of_fund', this.allocation_of_fund)
        data.append('particulars', this.particulars + '' + this.specify)
        data.append('cv_id', this.cv_id)
        data.append('ckv_id', this.ckv_id)
        data.append('ftv_id', this.ftv_id)
        data.append('request_data_id', this.request_data_id)

        data.append('is_dep_receivables', this.is_dep_receivables)
        data.append('dep_receivables', this.dep_receivables)
        data.append('is_ggc_dep', this.is_ggc_dep)
        data.append('ggc_dep_id', this.ggc_dep_id)
        data.append('is_return_fund', this.is_return_fund)
        data.append('is_return_fund', this.is_return_fund)
        data.append('is_ckv_return_fund', this.is_ckv_return_fund)
        data.append('return_fund', this.return_fund)
        data.append('return_fund_cheque', this.return_fund_cheque)
        data.append('is_ftv_deposit', this.is_ftv_deposit)
        data.append('ftv_deposit', this.ftv_deposit)
        data.append('is_auto_debit_ftv', this.is_auto_debit_ftv)
        data.append('auto_debit_ftv', this.auto_debit_ftv)
        data.append('is_auto_debit_ckv', this.is_auto_debit_ckv)
        data.append('auto_debit_ckv', this.auto_debit_ckv)
        data.append('is_auto_debit_cv', this.is_auto_debit_cv)
        data.append('auto_debit_cv', this.auto_debit_cv)
        data.append('is_cheque', this.is_cheque)
        await this.register_cash_flow(data)
          .then(() => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: 'SUCCESSFULLY ENCODED',
            })
          })
          .catch(error => {
            console.log(error)
          })
        await this.selected_bank()
        await this.selected_transaction()
        this.saving_data = false
      } else {
        this.alert = true
        this.alert_message = 'Please Fill up the field/s'
        this.saving_data = false
      }
    },
    async delete_bank_transaction(value) {
      this.saving_data = true
      const data = new FormData()
      data.append('id', value.id)
      await this.delete_cash_flow(data)
        .then(() => {
          this.change_snackbar({
            show: true,
            color: 'success',
            text: 'DELETED SUCCESSFULLY',
          })
        })
        .catch(error => {
          console.log(error)
        })
      await this.selected_bank()
      await this.selected_transaction()
      this.saving_data = false
    },
    async edit_details(value) {
      this.can_edit = true
      this.transaction_data = value
      this.key++
    },
    async on_change(value) {
      var color = 'error'
      if (value.status === 200) {
        color = 'success'
      }
      this.change_snackbar({
        show: true,
        color: color,
        text: value.data,
      })
      this.can_edit = false
      await this.selected_bank()
      await this.selected_transaction()
    },
    async final_report() {
      this.saving_data2 = true
      const data = new FormData()
      data.append('id', this.category_id)
      await this.category_to_final_report(data)
        .then(() => {
          this.change_snackbar({
            show: true,
            color: 'success',
            text: 'FINAL REPORT SUCCESSFULLY',
          })
        })
        .catch(error => {
          console.log(error)
        })
      await this.initialize_data()
      this.saving_data2 = false
    },
    async view_voucher() {
      this.printing_voucher = true
      var imgData = this.company_logo

      var damayan_ = this.damayan
      var chapel_ = this.chapel
      var factory_ = this.factory
      var coop_ = this.coop
      var printing_ = this.printing

      var payments_array = []
      var widths = []
      var info_array = []
      var cheque_array = {}
      var splited_particulars = this.particulars.split('#')
      var splited_particulars_voucher = this.particulars.split('-')
      var voc_no = splited_particulars_voucher[0]
      var existing_voucher = {}
      await this.view_voucher_from_cash_flow({
        voucher: this.particulars,
      })
        .then(response => {
          widths = [20, 40, 40, 60, 110, 70, 50, 40, 55]
          payments_array.push([
            {text: 'Req. ID', alignment: 'left', style: 'main_info'},
            {text: 'Category', alignment: 'left', style: 'main_info'},
            {
              text: splited_particulars[0] === 'FTV' ? 'From Bank' : 'Branch',
              alignment: 'left',
              style: 'main_info'
            },
            {
              text: splited_particulars[0] === 'FTV' ? 'To Bank' : 'Particulars',
              alignment: 'left',
              style: 'main_info'
            },
            {text: 'Description', alignment: 'left', style: 'main_info'},
            {text: 'Payment For', alignment: 'left', style: 'main_info'},
            {text: 'Schedule', alignment: 'left', style: 'main_info'},
            {text: 'Cheque#', alignment: 'left', style: 'main_info'},
            {text: 'Amount', alignment: 'left', style: 'main_info'},
          ])

          var status = 'APPROVED'
          var tott = 0

          existing_voucher = response.data[0]
          var data_items = existing_voucher.request_data

          data_items.forEach(function (item, index, payment) {
            if (item.pdc_status >= 1) {
              tott += parseFloat(item.amount)
            }

            payments_array.push([
              {
                text: item.request_id,
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.category.category,
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: splited_particulars[0] === 'FTV' ? item.ftv_from_bank.bank_code + '|' + item.ftv_from_bank.account_no : item.branch != null ? item.branch.branch_code : '',
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: splited_particulars[0] === 'FTV' ? item.ftv_to_bank.bank_code + '|' + item.ftv_to_bank.account_no : item.particulars.particulars,
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.description,
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.payment_for,
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.cheque_date,
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.cheque_no,
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text:
                  (item.pdc_status === 0 ? 'Not Count: ' : '') +
                  (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
            ])
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              payments_array.push([
                {
                  text: '',
                },
                {
                  text: '',
                },
                {
                  text: '',
                },
                {
                  text: '',
                },
                {
                  text: '',
                },
                {
                  text: '',
                },
                {
                  text: '',
                },
                {
                  text: 'TOTAL',
                  alignment: 'right',
                  style: 'tableExample2',
                },
                {
                  text: (tott / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2',
                },
              ])
            }
          })

          info_array = [
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'BANK: ',
                    {
                      text: existing_voucher.bank.bank_code,
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'MONTH OF: ',
                    {
                      text: this.month_of_voucher,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'ACCOUNT NO: ',
                    {
                      text: existing_voucher.bank.account_no,
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'VOUCHER #: ',
                    {
                      text: voc_no,
                      fontSize: 15,
                      bold: true,
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'ALLOCATION OF FUND: ',
                    {
                      text: existing_voucher.allocation_of_fund,
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'VOUCHER DATE: ',
                    {
                      text: existing_voucher.voucher_date,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'CHEQUE #: ',
                    {
                      text: existing_voucher.from_cheque_no + '-' + existing_voucher.to_cheque_no,
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'STATUS: ',
                    {
                      text: status,
                      fontSize: 15,
                      bold: true,
                      color: status === 'PENDING' ? 'red' : 'green',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'CHEQUE STATUS: ',
                    {
                      text:
                        existing_voucher.is_customize === 1
                          ? 'CUSTOMIZE CHEQUE'
                          : existing_voucher.from_cheque_no === '0'
                            ? 'AUTO DEBIT'
                            : 'REGULAR',
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'PAYEE: ',
                    {
                      text: existing_voucher.payee,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
          ]
        })
        .catch(error => {
          console.log(error)
        })

      //call pdfmake
      var pdfMake = require('pdfmake/build/pdfmake.js')
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require('pdfmake/build/vfs_fonts.js')
        pdfMake.vfs = pdfFonts.pdfMake.vfs
      }
      var docDefinition = {
        pageSize: 'LETTER',
        pageOrientation: 'portrait',
        content: [
          {
            columns: [
              {image: imgData, width: 60, height: 54, style: 'logo'},
              {
                stack: [
                  'GOODLIFE GROUP OF COMPANIES',
                  {text: this.address, style: 'subheader2'},
                  {
                    text: this.contact,
                    style: 'subheader2',
                  },
                  {
                    text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                    style: 'printed_label',
                  },
                ],
                style: 'header',
              },
              {
                image:
                  this.position === 'ADMIN'
                    ? this.admin
                    : this.position === 'TREASURY'
                      ? this.trea
                      : this.position === 'ACCOUNTING'
                        ? this.accounting
                        : this.position === 'AMD'
                          ? this.amd
                          : this.position === 'PROPERTY CUSTODIAN'
                            ? this.prot
                            : this.position === 'HUMAN RESOURCE'
                              ? this.hr
                              : this.position === 'BOOKKEEPING'
                                ? this.book
                                : this.position === 'CMD'
                                  ? this.accounting
                                  : imgData,
                width: 60,
                height: 54,
                style: 'logo',
              },
            ],
          },
          {
            stack: [
              {
                columns: [
                  {
                    text:
                      splited_particulars[0] === 'CV'
                        ? 'CASH VOUCHER'
                        : splited_particulars[0] === 'CKV'
                          ? 'CHEQUE VOUCHER'
                          : splited_particulars[0] === 'FTV'
                            ? 'FUND TRANSFER VOUCHER'
                            : '',
                    style: {
                      fontSize: 16,
                      alignment: 'center',
                      bold: true,
                    },
                  },
                ],
              },
            ],
          },
          '__________________________________________________________________________________________________',
          {
            text: 'CHEQUE DETAILS',
            style: {
              fontSize: 8,
              alignment: 'left',
              bold: true,
            },
          },
          {
            columns: [{text: ' '}],
          },
          {
            columns: [{text: ' '}],
          },
          {
            stack: info_array,
          },
          {
            columns: [{text: ' '}],
          },
          {
            table: {
              widths: widths,
              body: payments_array,
              style: {fontSize: 1},
            },
            layout: {
              fillColor: function (rowIndex) {
                return rowIndex === 0 ? '#CCCCCC' : null
              },
            },
          },
          {
            stack: [
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [
                  {
                    text: 'PROCESSED BY',
                    style: 'main_data_2',
                  },
                  {
                    text: 'DEPARTMENT RECEIVER',
                    style: 'main_data_2',
                  },
                  {
                    text: 'RECEIVED BY',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: existing_voucher.created_by.last_name + ', ' + existing_voucher.created_by.first_name + ' ' + existing_voucher.created_by.middle_name,
                    style: 'main_data_2',
                  },
                  {
                    text: ' ',
                  },
                  {
                    text: '',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: '_____________________',
                  },
                  {
                    text: '_____________________',
                  },
                  {
                    text: '_____________________',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: existing_voucher.created_by.employee_position.position,
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name',
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: '',
                  },
                  {
                    text: ' ',
                  },
                  {
                    text: ' ',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'VERIFIED BY',
                    style: 'main_data_2',
                  },
                  {
                    text: 'AUDITED BY',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: '_________________________________________',
                  },
                  {
                    text: '_________________________________________',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'BOARD OF DIRECTOR',
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name (TREASURY)',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: ' ',
                  },
                  {
                    text: ' ',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'APPROVED BY',
                    style: 'main_data_2',
                  },
                  {
                    text: '_________________________________________',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: ' ',
                  },
                  {
                    text: 'Signature Over Printed Name (ACCOUNTING)',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: ' ',
                  },
                  {
                    text: ' ',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'ALBERT M. HILARIO',
                    style: 'main_data_2',
                  },
                  {
                    text: '_________________________________________',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'EVP HEAD FINANCE',
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name (EXECUTIVE)',
                    style: 'main_data_2',
                  },
                ],
              },
            ],
          },

          cheque_array,
        ],
        footer: function (currentPage, pageCount) {
          return {
            margin: 10,
            columns: [
              {
                text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                fontSize: 9,
                alignment: 'left',
              },
              {image: damayan_, width: 34, height: 20, style: 'logo'},
              {image: chapel_, width: 34, height: 20, style: 'logo'},
              {image: factory_, width: 34, height: 20, style: 'logo'},
              {image: coop_, width: 34, height: 20, style: 'logo'},
              {image: printing_, width: 34, height: 20, style: 'logo'},
              {
                columns: [
                  {
                    text: 'VOUCHER #:',
                    fontSize: 9,
                    bold: true,
                    alignment: 'right',
                  },
                  {
                    text: voc_no,
                    fontSize: 10,
                    bold: true,
                    alignment: 'left',
                  },
                ],
              },
            ],
          }
        },
        styles: {
          tableExample: {
            fontSize: 9,
          },
          tableExample2: {
            fontSize: 7,
          },
          header: {
            fontSize: 21,
            bold: true,
            alignment: 'left',
            margin: [6, 6, 0, 5], //[left, top, right, bottom]
          },
          subheader: {
            fontSize: 11,
          },
          subheader2: {
            fontSize: 10,
          },
          logo: {
            alignment: 'center',
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          printed_label: {
            alignment: 'right',
            fontSize: 9,
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          main_data: {
            margin: [0, 2, 0, 2],
            fontSize: 9,
            bold: true,
            alignment: 'left',
          },
          main_data_2: {
            margin: [0, 2, 0, 2],
            fontSize: 8,
            bold: true,
            alignment: 'left',
          },
          main_info: {
            margin: [0, 2, 0, 2],
            fontSize: 10,
            alignment: 'left',
          },
        },
      }
      pdfMake.createPdf(docDefinition).open()
      this.printing_voucher = false
    },
  },
}
</script>
